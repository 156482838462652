<template>
  <div>
    <view-hero>
      <template slot="title">Agregar Respuestas</template>
      <template slot="description">
        ELHA es una plataforma que incluye a todos los trabajadores de la
        organización. Si tu encuesta se realiza de manera física, acá puedes
        subir la información para visualizar los resultados de manera integral
        en el Dashboard.
      </template>
    </view-hero>

    <custom-section>
      <template v-if="writtenAnswers.length">
        <div class="flex justify-between">
          <h2>Agregar Respuestas</h2>

          <div class="flex options">
            <div class="stat">
              {{ `Total: ${writtenAnswers.length}` }}
            </div>

            <custom-button variant="primary" to="/written-answers/new">
              <font-awesome-icon icon="plus" slot="icon" />
              Agregar Respuesta
            </custom-button>
          </div>
        </div>

        <div>
          <article
            v-for="(answer, index) in writtenAnswers"
            :key="answer.id"
            :to="`/written-answers/${answer.id}`"
            class="card"
          >
            <div>
              <h3>Respuesta N°{{ index + 1 }}</h3>
              <span>
                {{ formatDate(answer.createdAt) }}
              </span>
            </div>

            <div class="btn-container">
              <custom-button variant="ghost" @click="viewAnswer(answer.id)">
                <font-awesome-icon :icon="icons.view" slot="icon" />
              </custom-button>
              <custom-button variant="text" @click="onDelete(answer)">
                <font-awesome-icon icon="trash-alt" slot="icon" />
              </custom-button>
            </div>
          </article>
        </div>
      </template>

      <empty-screen
        v-else
        :icon="icons.emptyScreen"
        @ctaClick="$router.push('/written-answers/new')"
      >
        <template slot="title">No hay respuestas</template>
        <template slot="description">
          Si alguno de los miembros de tu organización respondió tu encuesta de
          forma manual, presiona el botón inferior para agregarla a tus
          resultados.
        </template>
        <template slot="btnText">Agregar Respuesta</template>
      </empty-screen>
    </custom-section>

    <view-answer-modal ref="answerModal" />

    <confirm-dialogue ref="confirmDialogue" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import { faEye, faList } from "@fortawesome/free-solid-svg-icons";

import CustomButton from "../../components/CustomButton.vue";
import CustomSection from "../../components/CustomSection.vue";
import ViewHero from "../../components/ViewHero.vue";
import EmptyScreen from "../EmptyScreen.vue";
import ViewAnswerModal from "./ViewAnswerModal.vue";
import ConfirmDialogue from "../../components/ConfirmDialogue.vue";

export default {
  name: "WrittenAnswers",

  components: {
    CustomButton,
    CustomSection,
    ViewHero,
    EmptyScreen,
    ViewAnswerModal,
    ConfirmDialogue
  },

  data: () => ({
    icons: {
      view: faEye,
      emptyScreen: faList
    }
  }),

  computed: {
    ...mapState(["writtenAnswers", "survey"])
  },

  methods: {
    ...mapActions(["fetchWrittenAnswers", "deleteWrittenAnswer"]),

    ...mapMutations(["setAlert"]),

    formatDate(rawDate) {
      if (rawDate instanceof Date) {
        return `Creada el ${rawDate.toLocaleDateString()} a las ${rawDate.toLocaleTimeString()}`;
      } else {
        return `Creada el ${rawDate
          .toDate()
          .toLocaleDateString()} a las ${rawDate
          .toDate()
          .toLocaleTimeString()}`;
      }
    },

    viewAnswer(answerId) {
      this.$refs.answerModal.open(answerId);
    },

    async onDelete(answer) {
      const ok = await this.$refs.confirmDialogue.show({
        title: "Eliminar Respuesta",
        message:
          "¿Estás seguro que quieres eliminar esta respuesta? Una vez eliminada no podrás recuperarla y no aparecerá en los resultados.",
        okButton: "Sí, eliminar",
        cancelButton: "No, volver",
        isDestructive: true
      });

      if (ok) {
        try {
          await this.deleteWrittenAnswer(answer.id);

          this.setAlert({
            state: "success",
            message: "Se eliminó la respuesta"
          });
        } catch (err) {
          console.log(err);
          this.setAlert({
            state: "error",
            message: "Ocurrió un error, por favor inténtalo nuevamente"
          });
        }
      }
    }
  },

  mounted() {
    this.fetchWrittenAnswers();
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.survey.active) next("/");
      else next();
    });
  }
};
</script>

<style scoped>
.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--lengthMd1);
  background-color: white;
}

.card:nth-child(odd) {
  background-color: #f7f7f7;
}

span {
  margin-top: var(--lengthSm1);
  color: var(--fontColor3);
  font-size: 0.75rem;
  font-weight: var(--medium);
}

.btn-container {
  margin-left: auto;
  grid-gap: var(--lengthMd2);
  gap: var(--lengthMd2);
}

.stat {
  padding: var(--lengthSm3);
  color: var(--fontColor3);
  font-size: 0.75rem;
  font-weight: var(--semi-bold);
  text-align: start;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: var(--grayColor1);
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm2);
}

.options {
  gap: var(--lengthSm2);
}
</style>
