<template>
  <modal-2 ref="modal">
    <template slot="title">
      {{ formatDate(answer.createdAt) }}
    </template>

    <section
      v-for="(feature, index) in Object.entries(answer.features || {})"
      :key="`feature-${index}`"
      class="section"
    >
      <span class="question">{{ feature[0] }}</span>

      <span class="answer">Respuesta: {{ feature[1] }}</span>
    </section>

    <section
      v-for="(answer, index) in answer.answers"
      :key="`answer-${index}`"
      class="section"
    >
      <div>
        <span class="question-number">{{ `Pregunta ${index + 1}` }}</span>
        <span class="question">{{ answer.question.text }}</span>
      </div>
      <span class="answer">
        Respuesta:
        {{
          typeof answer.answer === "object" ? answer.answer.text : answer.answer
        }}
      </span>
    </section>
  </modal-2>
</template>

<script>
import { mapState } from "vuex";

import Modal2 from "../../components/Modal2.vue";

export default {
  name: "ViewAnswer",

  components: { Modal2 },

  data: () => ({
    answerId: ""
  }),

  computed: {
    ...mapState(["writtenAnswers"]),

    answer() {
      return this.writtenAnswers.find(a => a.id === this.answerId) || {};
    }
  },

  methods: {
    formatDate(rawDate) {
      if (!rawDate) return "";

      if (rawDate instanceof Date) {
        return `Creada el ${rawDate.toLocaleDateString()} a las ${rawDate.toLocaleTimeString()}`;
      } else {
        return `Creada el ${rawDate
          .toDate()
          .toLocaleDateString()} a las ${rawDate
          .toDate()
          .toLocaleTimeString()}`;
      }
    },

    open(answerId) {
      this.answerId = answerId;
      this.$refs.modal.open();
    },

    close() {
      this.answerId = "";
      this.$refs.modal.close();
    }
  }
};
</script>

<style scoped>
.question-number {
  display: block;
  margin-bottom: var(--lengthSm1);
  color: var(--fontColor3);
  font-size: 0.7rem;
  font-weight: var(--medium);
  text-transform: uppercase;
}

.question {
  color: var(--fontColor1);
  font-size: var(--lengthMd1);
  font-weight: var(--medium);
}

.answer {
  color: var(--fontColor1);
  font-size: var(--lengthMd1);
  font-weight: var(--medium);
}

.section {
  display: flex;
  flex-direction: column;
  gap: var(--lengthMd1);
  padding: var(--lengthMd1);
  border-radius: var(--lengthSm1);
  border: 1px solid var(--grayColor2);
}

.section + .section {
  margin-top: var(--lengthMd1);
}
</style>
